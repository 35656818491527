<div class="footer container-fluid bg-dark mt-0 pt-4 pb-5">
<ul class="nav">
    <li class="nav-item">
      <a class="nav-link active" href="#">Impressum</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">Link</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">Link</a>
    </li>
   
  </ul>
</div>