<div class="container">
    <h2 class="text-center m-5">Travel Details</h2>
    <hr>

<!-- <div *ngIf="travel">
 <h3>{{ travel.title }} in {{ travel.wo }} </h3>
 <p>{{ travel.was }}</p>
 <p>{{ travel.preis }} €</p>
 <p>{{ travel.duration }}</p>

 <button (click)="addToCart(travel)">Buy</button>
</div>
</div> -->
<!-- style="max-width: 750px; -->
<div class="card mb-3 w-100">
    <div *ngIf="travel">
    <div class="row no-gutters">
      
        <div class="col-md-4">
            <img src="{{travel.img}}" class="card-img" alt="...">
        </div>
      
        <div class="col-md-8">
            <div class="card-body">
                <h5 class="card-title">{{ travel.title }} in {{ travel.wo }}</h5>
                <p class="card-text">{{ travel.was }}</p> 
                <p>{{ travel.duration }} <small>p.P.</small> {{ travel.preis }} €</p>
                <button class="btn btn-primary w-100 " (click)="addToCart(travel)"><svg class="bi bi-cart4" width="1.3em" height="1.3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
              </svg></button>
            </div>
        <!-- </div>

        <div class="col-md-2 pt-3">
           
        </div> -->

      </div>
    </div>
  </div>
</div>