<hr>
<h1 class="text-center mt-2 mb-4">book a travel</h1>
<hr>

<div class="row row-cols-1 row-cols-md-3">
    <div class="col mb-4" *ngFor="let travel of travels; index as productId">
      <div class="card h-100">
 
        <h5 class="text-dark p-2">{{travel.title}}</h5>
        <img src="{{travel.img}}" class="card-img-top" alt="pic">
        
          <div class="card-body">
            <h5 class="text-dark pl-2">{{travel.wo}}</h5>
            <!-- <p class="card-text pl-2  text-dark">{{travel.was}} </p> -->
            
            <p class="card-text pl-2">{{travel.duration}}</p>
            <p class="card-text pl-2">from <span class="font-weight-bold">{{travel.preis}} € </span>p.P.</p>
           
              <button type="button" [routerLink]="['/travels',productId]" class="btn btn-outline-primary w-100">Click for more informations</button>
           
            </div>
      </div>
    </div>
</div> 
