<hr>
<h1 class="text-center mt-2 mb-4">Welcome to our travel blog</h1>
<hr>

<!-- <div class="d-flex flex-wrap row col col-lg-3 md-4 sm-12 xs-12 justify-content-around">
    <div class="card" *ngFor="let blog of blogarr" >
      <img src="{{blog.img}}" class="card-img-top" alt="...">
      <div class="card-body">
        <h5 class="card-title">{{blog.title}}</h5>
        <p class="card-text">{{blog.was}}</p>
        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
      </div>
    </div>
  </div> -->


  <!-- <div class=" d-flex flex-wrap ">
     
  <div *ngFor="let blog of blogarr" class="card mb-3 bg-light mb-2 mx-auto" style="width: 17rem; height: auto;">
       <h5 class="text-dark p-2">{{blog.title}}</h5>
       <img src="{{blog.img}}" class="card-img-top img-fluid" style="height: 170px;;" alt="car">
       <div class="card-body">
        <h5 class="text-dark pl-2">{{blog.wo}}</h5>
       <p class="card-text pl-2 bg-light text-dark">{{blog.was}} </p>
       <p class="card-text"><small class="text-muted p-2">Last updated {{blog.wann}}</small></p>
       <p class="card-text"><small class="text-info p-2">for more information... <svg class="bi bi-plus-square" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"/>
        <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/>
        <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
      </svg></small></p>
      </div>
    </div>
  </div> -->

  <div class="row row-cols-1 row-cols-md-3">
    <div class="col mb-4" *ngFor="let blog of blogarr">
      <div class="card h-100">
        <h5 class="text-dark p-2">{{blog.title}}</h5>
        <img src="{{blog.img}}" class="card-img-top" alt="pic">
        <div class="card-body">
            <h5 class="text-dark pl-2">{{blog.wo}}</h5>
            <p class="card-text pl-2  text-dark">{{blog.was}} </p>
            <p class="card-text"><small class="text-muted p-2">Last updated {{blog.wann}}</small></p>
            <p class="card-text"><small class="text-info p-2">for more information... <svg class="bi bi-plus-square" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
             <path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"/>
             <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/>
             <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
           </svg></small></p>
        </div>
      </div>
    </div>
</div> 

