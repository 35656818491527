import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jumbotrom',
  templateUrl: './jumbotrom.component.html',
  styleUrls: ['./jumbotrom.component.css']
})
export class JumbotromComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
