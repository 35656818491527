<div class="jumbotron mb-0">
   <div class="container wrapper">
    <p class="lead pt-5 open">Experience The</p>
    <h1 class="display-4 italiano">Best Trip Ever</h1>
    <!-- <hr class="my-4"> -->
    <p class="w-50 opensmall ">We are commited to offering Travel services of the highest 
        quality combining our energy and enthusiasm with years of
        experience
    </p>
    <a class="btn btn-outline-secondary text-white btn-lg" href="#" role="button">Contact us</a>
  </div>
</div>
